<template>
  <div id="image_content">
    <notify-bar ref="notifyBar" />
    <dialog-window
      @submit-terminal-delete="onDeleteImageDialogWindow"
      @cancel="onCancelImageDialogWindow"
      :show="dialogWindow.show"
      :msg="dialogWindow.msg"
      :submitEvent="dialogWindow.submitEvent"
    />
    <div class="image_content_container">
      <div class="image_content_container__item title_container">
        <div class="title_container__item title">
          <label>Образы</label>
        </div>
        <div class="title_container__item">
          <button @click="onCreateImage" class="blue_button">Создать</button>
        </div>
      </div>
      <div class="image_content_container__item tool_bar_container">
        <div class="tool_bar_container__item search_input">
          <div class="search_input__item icon">
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.50006 0C8.53765 0 11.0001 2.46256 11.0001 5.50029C11.0001 6.74868 10.5842 7.89993 9.88346 8.82304L13.7791 12.7233C14.0718 13.0164 14.0715 13.4913 13.7785 13.784C13.4854 14.0767 13.0105 14.0764 12.7178 13.7834L8.82266 9.88388C7.89959 10.5847 6.74839 11.0006 5.50006 11.0006C2.46246 11.0006 0 8.53802 0 5.50029C0 2.46256 2.46246 0 5.50006 0ZM5.50006 1.5C3.2909 1.5 1.5 3.29098 1.5 5.50029C1.5 7.70961 3.2909 9.50058 5.50006 9.50058C7.70921 9.50058 9.50011 7.70961 9.50011 5.50029C9.50011 3.29098 7.70921 1.5 5.50006 1.5Z"
                fill="#A1A3AB"
              ></path>
            </svg>
          </div>
          <div class="search_input_item search_text" :class="filterName ? 'typed' : ''">
            <input
              @input="(event) => (filterName = event.target.value)"
              placeholder="Image type"
            />
          </div>
        </div>
        
      </div>
      <div class="image_content_container__item">
        <image-table
          @select-image="onSelectImage"
          @delete-image="onDeleteImage"
          :images="getFilteredImages"
        />
        <image-panel
          v-if="selectedImage !== null"
          @close-image-panel="selectedImage = null"
          @save-image="onSaveImage"
          :image="getSelectedImage"
          :panelMode="this.panelMode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageTable from "../components/ImageTable.vue";
import ImagePanel from "../components/ImagePanel.vue";
import DialogWindow from "../components/DialogWindow.vue";
import NotifyBar from "../components/NotifyBar.vue";

export default {
  components: {
    "image-table": ImageTable,
    "image-panel": ImagePanel,
    "dialog-window": DialogWindow,
    "notify-bar": NotifyBar,
  },
  data() {
    return {
      selectedImage: null,
      images: [],
      toDelImage: null,
      filterName: null,
      dialogWindow: {
        show: false,
        msg: "",
        submitEvent: "submitTerminalDelete",
      },
      panelMode: "EDIT", // EDIT/NEW
    };
  },
  methods: {
    getImages() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/images/types`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy obtained (get image_types)");
          if (res.data.statusCode === 200) {
            this.images = [];
            res.data.payload.imagesTypes.forEach((el) => {
              this.images.push({
                type: el.imagesType,
                defaultImage: el.defaultImage,
                count: el.imagesCount,
                limit: el.imagesLimit,
                images: [...new Set(el.images)],
              });
            });
            this.images = this.images.sort((a, b) => {
              let x = a.type.toLowerCase();
              let y = b.type.toLowerCase();
              if (x > y) {
                return 1;
              }
              if (x < y) {
                return -1;
              }
              return 0;
            });
            return;
          } else {
            console.log("Response proxy error (get image_types)", res.data);
          }
        })
        .catch((error) => {
          console.log("Error to get images", error);
        });
    },
    onSaveImage() {
      if (this.panelMode === "NEW") {
        this.selectedImage = null;
      }
      
      setTimeout(() => {
        this.getImages();
            }, 300);
    },
    onCreateImage() {
      this.selectedImage = -1;
      this.panelMode = "NEW";
    },
    onSelectImage(i) {
      this.selectedImage = i;
      this.panelMode = "EDIT";
    },
    onDeleteImage(type) {
      this.toDelImage = type;
      this.dialogWindow = {
        show: true,
        msg: `Удалить образ: ${type}`,
        submitEvent: "submitTerminalDelete",
      };
    },
    onDeleteImageDialogWindow() {
      this.deleteImage(this.images.find((el) => el.type === this.toDelImage).type);
      this.dialogWindow.show = false;
    },
    onCancelImageDialogWindow() {
      this.dialogWindow.show = false;
    },
    deleteImage(image) {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Удаление образа",
      });
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/images/types?imagesType=${image}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy api obtained (del image options)", res);

          if (res.data.statusCode === 200) {
            this.getImages();
            this.$refs.notifyBar.addOKMsg({
              overWriteMsg: "Образ удален",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка удаления образа",
          });
        })
        .catch((error) => {
          console.log("Error to del image options", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка удаления образа",
          });
        });
    },
  },
  created() {
    this.getImages();
  },
  computed: {
    getFilteredImages() {
      if (this.filterName) {
        return this.images.filter((el) => {
          console.log("el", el, el.type.includes(this.filterName), this.filterName)
          return el.type.includes(this.filterName);
        });
      }
      return this.images;
    },
    getSelectedImage() {
      if (this.selectedImage == -1) {
        return {
          type: "",
          limit: 10,
          required: false,
          description: "",
          tag: "NEW",
        };
      }
      return this.images.find((el) => el.type === this.selectedImage);
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
@import "../assets/styles/inputs.less";

.image_content_container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  .tool_bar_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;

    .blue_button {
      height: 20px;
    }
  }
}

.title_container {
  display: flex;
  justify-content: space-between;

  .title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  margin-bottom: 20px;
}
</style>
