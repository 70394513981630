<template>
  <div id="image_panel">
    <div class="image_info_container">
      <div class="image_info_container__item header_container">
        <div class="header_container__item title">
          <label v-if="panelMode === 'EDIT'">{{ getImage.type }}</label>
          <label v-else>Новый образ</label>
        </div>
        <div
          class="header_container__item right_buttons_container"
          :style="panelMode === 'NEW' ? 'justify-content: flex-end;' : ''"
        >
          <div
            v-if="panelMode === 'EDIT'"
            class="right_buttons_container__item"
          >
            <button title="Экспорт" @click="downloadImage">
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00016 0.833984L3.66683 4.16732H6.16683V11.6673H7.8335V4.16732H10.3335M12.0002 19.1673H2.00016C1.55814 19.1673 1.13421 18.9917 0.821651 18.6792C0.509091 18.3666 0.333496 17.9427 0.333496 17.5006V7.50065C0.333496 7.05862 0.509091 6.6347 0.821651 6.32214C1.13421 6.00958 1.55814 5.83398 2.00016 5.83398H4.50016V7.50065H2.00016V17.5006H12.0002V7.50065H9.50016V5.83398H12.0002C12.4422 5.83398 12.8661 6.00958 13.1787 6.32214C13.4912 6.6347 13.6668 7.05862 13.6668 7.50065V17.5006C13.6668 17.9427 13.4912 18.3666 13.1787 18.6792C12.8661 18.9917 12.4422 19.1673 12.0002 19.1673Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div class="right_buttons_container__item">
            <button @click="$emit('closeImagePanel')">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.33317 11.8346L0.166504 10.668L4.83317 6.0013L0.166504 1.33464L1.33317 0.167969L5.99984 4.83464L10.6665 0.167969L11.8332 1.33464L7.1665 6.0013L11.8332 10.668L10.6665 11.8346L5.99984 7.16797L1.33317 11.8346Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="image_info_container__item action_status_container">
        <action-bar
          :show="actionBarData.show"
          @close-action-bar="actionBarData.show = false"
          :status="actionBarData.status"
          :action="actionBarData.action"
          :broadDescription="actionBarData.broadDescription"
        />
      </div>

      <div class="image_info_container__item image_panel_content_container">
        <div
          v-if="panelMode === 'NEW'"
          class="image_panel_content_container__item description"
        >
          <div class="input_with_title">
            <div class="input_with_title__item input_title">Название</div>
            <div class="input_with_title__item input_select">
              <input
                :value="getImage.type"
                type="text"
                @input="(event) => (settings.type = event.target.value)"
                v-on:keypress="isLetter($event)"
              />
            </div>
          </div>
        </div>
        <div class="image_panel_content_container__item limit">
          <div class="input_with_title">
            <div class="input_with_title__item input_title">Лимит</div>
            <div class="input_with_title__item input_select">
              <input
                type="number"
                min="0"
                :value="getImage.limit"
                @input="(event) => (settings.limit = event.target.value)"
              />
            </div>
          </div>
        </div>
        <div
          v-if="panelMode === 'EDIT'"
          class="image_panel_content_container__item default_dist"
        >
          <div class="select_input_with_title">
            <div class="select_input_title">Образ</div>
            <div class="input_select">
              <search-select
                  :options="getImage.images"
                  v-model:selected="settings.defaultImage"
                  style="width: 95%"
                />
            </div>
          </div>
        </div>
        <div class="image_panel_content_container__item save">
          <button
            @click="isChanged ? onSaveImage() : null"
            :class="isChanged ? 'blue_button' : 'gray_button'"
          >
            {{ panelMode === "EDIT" ? "Сохранить" : "Создать" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "action-bar": defineAsyncComponent(() =>
      import("@/components/ActionBar.vue")
    ),
    "search-select": defineAsyncComponent(() =>
      import("@/components/SearchSelect.vue")
    ),
  },
  props: {
    panelMode: {
      type: String,
      default() {
        return "EDIT";
      },
    },
    image: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      settings: {
        type: null,
        limit: null,
        defaultImage: null,
      },
      actionBarData: {
        show: false,
        status: null,
        action: null,
        broadDescription: null,
      },
    };
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z0-9]+|[.,-_]+/g.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    setDefaultImage(defaultImage) {
      this.actionBarData = {
        status: "WAITING",
        action: "Установка базового образа",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/images/default?type=${this.getImage.type}&image=${defaultImage}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set default image)",
            res
          );

          if ([200, 201].includes(res.data.statusCode)) {
            this.actionBarData.status = "OK";
            return;
          }

          this.actionBarData.status = "ERROR";
          this.actionBarData.broadDescription = res.data.payload;
        })
        .catch((error) => {
          console.log("Error to set default images", error);
          if (error.request.status === 403) {
            this.actionBarData.status = "ERROR";
            (this.actionBarData.action = "Сохранение"),
              (this.actionBarData.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.status = "ERROR";
          this.actionBarData.broadDescription = error.data;
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },

    downloadImage() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.image, null, 2));
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute(
        "download",
        `image_${this.image.type}_export` + ".json"
      );
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },

    onSaveImage() {
      this.actionBarData = {
        status: "WAITING",
        action: "Установка параметров",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/images/types`,
          method: "POST",
          payload: {
            imagesType: this.getImage.type,
            imagesLimit: this.getImage.limit,
            systemRequirements: {
              cpu: 0,
              gpu: 0,
              ram: 0,
              resolutions: [],
              vendors: [],
            },
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set image options)",
            res
          );

          if ([200, 201].includes(res.data.statusCode)) {
            this.actionBarData.status = "OK";
            if (this.settings.defaultImage !== null) {
              this.setDefaultImage(this.settings.defaultImage);
            }
            this.actionBarData = {
              status: "WAITING",
              action: "Сохранение",
              show: true,
            };
            this.$emit("saveImage");
            setTimeout(() => {
              this.ActionBar.show = false;
            }, 4000);
            return;
          }

          this.actionBarData.status = "ERROR";
          this.actionBarData.broadDescription = res.data.payload;
        })
        .catch((error) => {
          console.log("Error to set image options", error);
          if (error.request.status === 403) {
            this.actionBarData.status = "ERROR";
            (this.actionBarData.action = "Сохранение"),
              (this.actionBarData.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.status = "ERROR";
          this.actionBarData.broadDescription = error.data;
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },
  },
  computed: {
    getImage() {
      return {
        type: this.settings.type ? this.settings.type : this.image.type,
        defaultImage: this.settings.defaultImage
          ? this.settings.defaultImage
          : this.image.defaultImage,
        limit:
          this.settings.limit !== null ? this.settings.limit : this.image.limit,
        images: this.image.images,
      };
    },
    isChanged() {
      if (
        this.panelMode === "EDIT" &&
        (this.getImage.limit !== this.image.limit ||
          this.getImage.defaultImage !== this.image.defaultImage)
      ) {
        return true;
      }
      if (
        this.panelMode === "NEW" &&
        (this.getImage.limit !== this.image.limit ||
          this.getImage.type !== this.image.type)
      ) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.settings.defaultImage = this.getImage.defaultImage;
  },
};
</script>

<style lang="less">
@import "../assets/styles/inputs.less";
@import "../assets/styles/buttons.less";

#image_panel {
  .image_info_container {
    position: absolute;
    width: 801px;
    height: 100%;
    right: 0;
    top: 72px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .action_status_container {
      min-height: 30px;
      max-height: 100px;
      display: flex;
      align-items: center;
      padding: 15px 0px 0px 25px;
    }

    .limit {
      input {
        width: 121px;
      }
    }
    .description {
      input {
        width: 307px;
      }
    }
    .default_dist {
      select {
        width: 315px;
      }
    }
  }

  .header_container {
    display: flex;
    height: 49px;
    align-items: center;
    justify-content: space-between;
    background: #d6e6ff;
    padding: 0px 25px;

    button {
      all: unset;
    }
  }

  .image_panel_content_container {
    padding: 2px 0px 0px 25px;

    .image_panel_content_container__item {
      margin-bottom: 25px;
    }
  }

  .right_buttons_container {
    width: 60px;
    display: flex;
    justify-content: space-between;

    button {
      width: 27px;
      height: 27px;
      display: flex;
      border-radius: 50px;
      justify-content: center;
      align-items: center;
    }

    button:hover {
      background-color: #4151b761;
      border-radius: 50px;
      transition: 0.3s;
    }

    button:active {
      outline-color: transparent;
      outline-style: solid;
      box-shadow: 0 0 0 6px #4150b7;
      background-color: none;
    }
  }

  .require_container {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
  }
}
</style>
